import * as React from "react"
import { Link } from "gatsby"

// Importing icons
import { FaShoppingCart } from "react-icons/fa"

export function CartButton({ quantity }) {
  return (
    <Link
      className="cart-button"
      aria-label={`Shopping Cart with ${quantity} items`}
      to="/cart"
    >
      <FaShoppingCart />
      {quantity > 0 && <div className="badge">{quantity}</div>}
    </Link>
  )
}
