import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

// Importing styles
import "./styles/footer.scss"

// Importing Bootstrap components
import { Container, Row } from "react-bootstrap"

// Importing icons
import {
  FaCcAmex,
  FaCcApplePay,
  FaCcDiscover,
  FaCcVisa,
  FaFacebookSquare,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa"

export function Footer() {
  return (
    <footer>
      <Container>
        <StaticImage
          className="image"
          src="../assets/images/footer-logo.png"
          alt="The Elevated Life logo."
          placeholder="tracedSVG"
        />
        <div className="payments">
          <h5>Accepted forms of payment:</h5>
          <ul>
            <li>
              <FaCcVisa />
            </li>
            <li>
              <FaCcDiscover />
            </li>
            <li>
              <FaCcAmex />
            </li>
            <li>
              <FaCcApplePay />
            </li>
          </ul>
          <p className="talk">
            All sales will show as <strong>ElevatedLifePa</strong> on your
            billing statement.
          </p>
        </div>
        <div className="questions">
          <h5>
            <strong>Questions?</strong>
          </h5>
          <p className="talk">
            <span>Phone:</span> <a href="tel:8778764296">877-876-4296</a> |{" "}
            <span>Email: </span>
            <a href="mailto:info@elevatedlifepa.com">info@elevatedlifepa.com</a>
            <br />
            <span>Address: </span>
            PO Box 15821 Pittsburgh PA 15244 1001
          </p>
        </div>
        <div className="social">
          <ul>
            <li>
              <a href="https://www.facebook.com/ElevatedLifePA/">
                <FaFacebookSquare />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/elevatedlifepa/">
                <FaInstagram />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCB4sgF-wqs5Y_Qq7OM1EWJw">
                <FaYoutube />
              </a>
            </li>
          </ul>
        </div>
        <div className="copyright">
          Copyright Elevated Life &copy; {new Date().getFullYear()}
          <br /> All rights reserved
          <br />
          <br />
          <Link className="link" to="/terms-and-conditions">
            Terms & Conditions
          </Link>{" "}
          /{" "}
          <Link className="link" to="/policies">
            Policies
          </Link>
        </div>
      </Container>
    </footer>
  )
}
